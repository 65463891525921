import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { adminAPI } from '../../../api';

import useModal from '../../../context/ModalContext';
import useAlarm from '../../../context/AlarmContext';
import FormLoader from '../../../components/FormLoader';

const ResendInviteModal = ({ items, setItems }) => {
  const { setAlarm } = useAlarm();
  const { setContent, closeModal } = useModal();

  const queryClient = useQueryClient();

  const { mutate, isLoading: mutationIsLoading } = useMutation(
    adminAPI.resendInvites,
    {
      onSuccess: (data) => {
        setAlarm({ type: 'success', message: data.message });
        setContent({ title: null, body: null });
        setItems([]);
        queryClient.invalidateQueries('pendingInvites');
        closeModal();
      },
      onSettled: (data, error) => {
        if (error) {
          setAlarm({ type: 'error', message: error.message });
          closeModal();
        }
      },
    }
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    const serializedData = JSON.stringify(items);
    mutate(serializedData);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <h2 className=" font-semibold">Resend Invites</h2>
        <p>You are resending invitation emails to the following users.</p>
        <div
          style={{ maxHeight: '400px' }}
          className="border-2 border-gray rounded-2lg p-4 overflow-y-scroll my-8"
        >
          {items.map((item) => (
            <p>{item.email}</p>
          ))}
        </div>

        <button
          type="submit"
          className=" font-semibold p-4 rounded-lg bg-green-light text-white mt-4"
        >
          Resend Invites
        </button>
      </form>
      {mutationIsLoading && <FormLoader />}
    </>
  );
};

export default ResendInviteModal;
