import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import UserInviteForm from '../../UserInviteForm';

import useAlarm from '../../../context/AlarmContext';
import FormLoader from '../../FormLoader';
import SimplifiedTable from '../../SimplifiedUserTable';
import { adminAPI } from '../../../api';
import ApplicationSelect from './invite/ApplicationsSelect';
import EmailInputList from './invite/EmailInputList';

const IndependentUserInviteForm = ({
  setOpenSideActionBoard,
  items,
  title,
  type,
  setCheckedActiveUsers,
  setUnconfirmedUsers,
  setPendingInvites,
}) => {
  const [emailList, setEmailList] = useState([]);
  const [application, setApplication] = useState('ALD+ALEX');

  const { data, isLoading } = useQuery(
    'invidation_data',
    adminAPI.getInvitationData
  );

  const [formData, setFormData] = useState({
    access_type: '5 Free Videos',
    expires_at: null,
    unlimited_gifting: false,
    invitation_access: 'limited',
    skip_email: false,
    course_ids: null,
    discount_cents: null,
    opt_out_eop: false,
    expiration_type: 'length',
    valid_for_days: null,
    length_type: 'days',
  });
  const [selected, setSelected] = useState(items);
  const [includedFields, setIncludedFields] = useState([
    'emails',
    'skip_email',
  ]);

  const toggleIncludedField = (toggle, field) => {
    if (toggle) {
      setIncludedFields((prev) => [...prev, field]);
    } else {
      setIncludedFields((prev) => prev.filter((f) => f !== field));
    }
  };

  const handleChange = (name, value, parent = null) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    if (application === 'ALEX') {
      handleChange('skip_email', false, 'user_invite');
      handleChange('access_type', 'ALEX', 'user_invite');
    } else {
      handleChange('skip_email', false, 'user_invite');
      handleChange('access_type', '5 Free Videos', 'user_invite');
    }
  }, [application]);

  const queryClient = useQueryClient();

  const queryListId =
    type === 'Active Users'
      ? 'activeUsers'
      : type === 'Unconfirmed Users'
      ? 'unconfirmedUsers'
      : 'pendingInvites';

  const { setAlarm } = useAlarm();
  const { mutate, isLoading: mutationIsLoading } = useMutation(
    adminAPI.inviteUsers,
    {
      onSuccess: (data) => {
        setAlarm({ type: 'success', message: data.message });
        setOpenSideActionBoard();
        if (type === 'Active Users') setCheckedActiveUsers([]);
        if (type === 'Unconfirmed Users') setUnconfirmedUsers([]);
        if (type === 'Pending Invites') setPendingInvites([]);
        queryClient.invalidateQueries(queryListId);
        queryClient.invalidateQueries('userDetails');
      },
      onError: (error) => {
        setAlarm({ type: 'error', message: error.message });
      },
    }
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    formData.application = application;
    if (selected) {
      formData.emails = selected.map((item) => item.email);
    } else {
      formData.emails = emailList;
    }
    if (items) {
      const filteredFormData = {};
      Object.keys(formData).forEach((key) => {
        if (includedFields?.includes(key)) {
          filteredFormData[key] = formData[key];
        }
      });
      const serializedData = JSON.stringify(filteredFormData);
      mutate(serializedData);
    } else {
      const serializedData = JSON.stringify(formData);
      mutate(serializedData);
    }
  };

  useEffect(() => {
    if (type === 'Active Users') {
      setCheckedActiveUsers(selected);
    }
    if (type === 'Unconfirmed Users') {
      setUnconfirmedUsers(selected);
    }
    if (type === 'Pending Invites') {
      setPendingInvites(selected);
    }
  }, [selected]);

  return (
    !isLoading &&
    data && (
      <form className="p-8 overflow-y-scroll h-full" onSubmit={handleSubmit}>
        <h2 className="mb-8  font-semibold">{title}</h2>
        <ApplicationSelect
          onChange={(newApplication) => setApplication(newApplication)}
        />
        {!items ? (
          <>
            <EmailInputList
              onChangeEmailList={(emails) => setEmailList(emails)}
            />
          </>
        ) : (
          <>
            <p className="text-sm uppercase text-charcoal  font-bold mb-4">
              Selected Users
            </p>
            <SimplifiedTable
              data={items}
              checkedItems={selected}
              setCheckedItems={setSelected}
            />
          </>
        )}

        <UserInviteForm
          formData={{ user_invite: formData }}
          courses={data.enabled_courses}
          handleChange={handleChange}
          hubspotAccessTypes={data.hubspot_options}
          limitFields={!!items}
          toggleIncludedField={toggleIncludedField}
          includedFields={includedFields}
          application={application}
        />
        <button
          className="p-4 my-10  bg-purple text-white font-semibold rounded-lg"
          type="submit"
        >
          {items ? 'Update Users' : 'Send Invitations'}
        </button>
        {mutationIsLoading && <FormLoader />}
      </form>
    )
  );
};

export default IndependentUserInviteForm;
