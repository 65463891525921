import React, { useState } from 'react';
import TabSelection from './TabSelection';
import SideBar from '../../../components/AdminUserSideBar';
import AlexUsage from './AlexUsage';
import DailyAlexUsage from './DailyAlexUsage';

const tabs = [
  {
    label: 'Alex Usage',
    show: { search: true, filters: { date: true, email: true } },
  },
  {
    label: 'Daily Alex Usage',
    show: { search: true, filters: { date: true, email: true } },
  },
];

const Tabs = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filter, setFilter] = useState({});
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const [openSideActionBoard, setOpenSideActionBoard] = useState(false);

  return (
    <div>
      {openSideActionBoard && (
        <SideBar
          openSideActionBoard={openSideActionBoard}
          setOpenSideActionBoard={setOpenSideActionBoard}
        />
      )}
      <TabSelection
        tabs={tabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filter={filter}
        setFilter={setFilter}
      />
      <div style={{ gap: '64px' }} className="flex flex-col">
        {currentTab?.label === 'Alex Usage' && (
          <AlexUsage searchQuery={searchQuery} filter={filter} />
        )}
      </div>
      <div style={{ gap: '64px' }} className="flex flex-col">
        {currentTab?.label === 'Daily Alex Usage' && (
          <DailyAlexUsage searchQuery={searchQuery} filter={filter} />
        )}
      </div>
    </div>
  );
};

export default Tabs;
