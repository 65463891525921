import React, { useState } from 'react';
import SVG from 'react-inlinesvg';

const FormInput = ({
  label,
  handleChange,
  type,
  name,
  value,
  icon,
  accept = '',
  style = {},
  htmlType = 'input',
  disabled = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const inputProps = {
    className: 'focus:outline-none w-full',
    name,
    type,
    ...(type === 'file' ? { accept } : { value }),
    style,
    onFocus: () => setIsFocused(true),
    onBlur: () => setIsFocused(false),
    onChange: handleChange,
    disabled,
  };

  return (
    <div style={{ gap: '0' }} className="flex flex-col">
      {label && (
        <label className="text-sm text-charcoal font-bold pb-2">{label}</label>
      )}
      <div
        className={`px-2 py-2 flex items-center border-2 ${
          isFocused ? 'border-purple' : ''
        }`}
        style={{ borderRadius: '12px', minWidth: '100%' }}
      >
        {htmlType === 'input' && (
          <>
            {icon && <SVG className="mr-4" src={icon} />}
            <input {...inputProps} />
          </>
        )}
        {htmlType === 'textarea' && (
          <>
            {icon && <SVG className="mr-4" src={icon} />}
            <textarea {...inputProps} />
          </>
        )}
      </div>
    </div>
  );
};
export default FormInput;
